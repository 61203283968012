<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          Vestaorganic
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            style="max-height: 500px"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 pt-5 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Личный кабинет партнера
          </b-card-title>
          <b-card-text class="mb-2">
            Пожалуйста, войдите в свою учетную запись
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Логин"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Логин"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    :disabled="loading"
                    name="login-email"
                    placeholder="Номер, телефон или email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                  <b-link
                    :to="{name:'forgot-password'}"
                    tabindex="3"
                  >
                    <small>Войти по коду</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      :disabled="loading"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="loading"
                @click="validationForm"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-50"
                  />
                  <span>Войти</span>
                </div>
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2 mb-2">
            <span>Впервые на нашей платформе? </span>
            <b-link :to="{ name: 'signup' }">
              <span>&nbsp;Зарегистрироваться</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput,
  BInputGroupAppend, BInputGroup, BCardText,
  BCardTitle,
  BImg,
  BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/vestabg.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/vestabg.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.loading = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.username,
            password: this.password,
          }).then(response => {
            const { data } = response.data
            const { userData } = data

            useJwt.setToken(data.accessToken)
            useJwt.setRefreshToken(data.refreshToken)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$store.commit('app/SET_USER', userData)
            this.$store.commit('app/SET_LOGGED_IN', true)
            this.loading = false

            window.location.replace('/')

            // if (userData.tmp) {
            //   window.location.replace(`/${userData.tmp}`)
            // } else {
            //   window.location.replace('/')
            // }
          }).catch(error => {
            this.$refs.loginValidation.setErrors(error)
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
